import React, { useEffect, useRef } from 'react'
import * as THREE from 'three';

export const Waves = ({ children }) => {
    const mount = useRef(0)
    useEffect(() => {
        // === THREE.JS CODE START ===

        var SEPARATION = 50, AMOUNTX = 80, AMOUNTY = 70;

        var container;
        var camera, scene, renderer;

        var animationId

        var particles, count = 0;

        var mouseX = 0, mouseY = 0;

        var windowHalfX = window.innerWidth / 2;
        var windowHalfY = window.innerHeight / 2;

        init();
        animate();

        function init() {

            container = document.createElement('div');

            // document.body.appendChild( renderer.domElement );
            // use ref as a mount point of the Three.js scene instead of the document.body
            mount.current.appendChild( container );
            // document.body.appendChild(container);

            camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
            camera.position.z = 2000;
            camera.position.y = 1000;

            scene = new THREE.Scene();

            //

            var numParticles = AMOUNTX * AMOUNTY;

            var positions = new Float32Array(numParticles * 3);
            var scales = new Float32Array(numParticles);

            var i = 0, j = 0;

            for (var ix = 0; ix < AMOUNTX; ix++) {

                for (var iy = 0; iy < AMOUNTY; iy++) {

                    positions[i] = ix * SEPARATION - ((AMOUNTX * SEPARATION) / 2); // x
                    positions[i + 1] = 0; // y
                    positions[i + 2] = iy * SEPARATION - ((AMOUNTY * SEPARATION) / 2); // z

                    scales[j] = 1;

                    i += 3;
                    j++;

                }

            }

            var geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
            geometry.setAttribute('scale', new THREE.BufferAttribute(scales, 1));
            var material = new THREE.ShaderMaterial({



                uniforms: {
                    color: { value: new THREE.Color(0xffffff) },
                    
                },
                //  vertexShader: document.getElementById( 'vertexshader' ).textContent,
                vertexShader: `attribute float scale;

                void main() {
    
                    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    
                    gl_PointSize = scale * ( 300.0 / - mvPosition.z );
    
                    gl_Position = projectionMatrix * mvPosition;
    
                }`,
                //  fragmentShader: document.getElementById( 'fragmentshader' ).textContent
                fragmentShader: `uniform vec3 color;

                    void main() {
        
                        if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;
        
                        gl_FragColor = vec4( color, 1.0 );
        
                    }
                `

            });

            //

            particles = new THREE.Points(geometry, material);
            scene.add(particles);

            //

            renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(window.innerWidth, window.innerHeight);
            container.appendChild(renderer.domElement);

            //  stats = new Stats();
            //  container.appendChild( stats.dom );

            document.addEventListener('mousemove', onDocumentMouseMove, false);
            document.addEventListener('touchstart', onDocumentTouchStart, false);
            document.addEventListener('touchmove', onDocumentTouchMove, false);

            //

            window.addEventListener('resize', onWindowResize, false);

        }

        function onWindowResize() {

            windowHalfX = window.innerWidth / 2;
            windowHalfY = window.innerHeight / 2;

            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();

            renderer.setSize(window.innerWidth, window.innerHeight);

        }

        //

        function onDocumentMouseMove(event) {

            mouseX = event.clientX - windowHalfX;
            mouseY = event.clientY - windowHalfY;

        }

        function onDocumentTouchStart(event) {

            if (event.touches.length === 1) {

                event.preventDefault();

                mouseX = event.touches[0].pageX - windowHalfX;
                mouseY = event.touches[0].pageY - windowHalfY;

            }

        }

        function onDocumentTouchMove(event) {

            if (event.touches.length === 1) {

                event.preventDefault();

                mouseX = event.touches[0].pageX - windowHalfX;
                mouseY = event.touches[0].pageY - windowHalfY;

            }

        }

        //

        function animate() {

            requestAnimationFrame(animate);
            camera.position.y = 400
            camera.position.z = 400
            render();
        }

        function render() {

            camera.position.x += (mouseX - camera.position.x) * .02;
            camera.position.y += (- mouseY - camera.position.y) * .02;

            // console.log((- mouseY - camera.position.y) * .02)
            camera.lookAt(scene.position);

            var positions = particles.geometry.attributes.position.array;
            var scales = particles.geometry.attributes.scale.array;

            var i = 0, j = 0;

            for (var ix = 0; ix < AMOUNTX; ix++) {

                for (var iy = 0; iy < AMOUNTY; iy++) {

                    positions[i + 1] = (Math.sin((ix + count) * 0.3) * 50) +
                        (Math.sin((iy + count) * 0.5) * 50);

                    scales[j] = (Math.sin((ix + count) * 0.3) + 1) * 8 +
                        (Math.sin((iy + count) * 0.5) + 1) * 8;

                    i += 3;
                    j++;

                }

            }

            particles.geometry.attributes.position.needsUpdate = true;
            particles.geometry.attributes.scale.needsUpdate = true;

            renderer.render(scene, camera);

            count += 0.1;

        }

        // === THREE.JS EXAMPLE CODE END ===

        return () => {
            window.removeEventListener("resize", onWindowResize);
            window.cancelAnimationFrame(window.requestAnimationFrame(animate));
            mount.current.removeChild(container)
        }

    }, [])
    return (
        <div 
        className="absolute h-full w-full" ref={mount}>
        </div>
    )
}
