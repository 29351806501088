import React from 'react'
import PropTypes from 'prop-types'
import { useEffect } from 'react';
import { Waves } from '../components/animations/Waves';
import { Section } from '../pages/index'
import Layout from '../components/layout/Layout';
import { Button } from '../components/elements';
import SEO from "@layout/SEO"

const AboutUs = props => {


    return (
        <>
        <SEO title="About Us" description="A unique collective of people; collaborating and creating web focused products and services." />
        <Layout>
            <Section style={{
                background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
                backgroundSize: '400% 400%',
                animation: 'gradient 15s ease infinite'
            }} fullWidth={true} >


                <div className="w-full min-h-screen flex flex-col justify-center px-8 relative z-20 ">
                    <div style={{
                        transform: 'rotate(3deg)', background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
                        backgroundSize: '400% 400%',
                        animation: 'gradient 10s ease infinite'
                    }} className="bg-primary py-8 px-4 inline-block max-w-5xl mx-auto self-center ">
                        <h1 style={{ transform: 'rotate(-3deg)' }} className="text-center self-center w-full text-gray-100 text-3xl md:text-4xl lg:text-5xl px-2 md:px-12">A unique collective of people separate, <br /> but together</h1>
                    </div>
                </div>
                <Waves />
            </Section>


            <Section fullHeight={false} fullWidth bgColor="bg-white flex-col px-2 pt-12 lg:pt-24">

                <div className="grid grid-cols-1 md:grid-cols-2 max-w-5xl mx-auto">
                    <div className="flex flex-col justify-center order-2 md:order-1">
                        <h2>Our Story</h2>
                        <p className=" text-xl leading-loose my-4 self-center">
                            We started as a one-man-band. Due to a unique experience of trying to wear as many hats as possible our founder had experience in multiple areas that allowed him to freelance work with different clients across multitudes of projects - from corporate headshots to charity videos, and outsource consulting to in house app design.
                        </p>
                    </div>
                    <img className="order-1 md:order-2 w-2/3 md:w-full mx-auto mb-8 md:mb-4" src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/7fce45bc-395d-4b0a-849d-dc4d1d87c761/d4wldsk-071a8520-51a8-4e2a-a8a0-b560acc074e1.png/v1/fill/w_800,h_800,q_80,strp/funny_tombow__the_one_man_band_by_artieyoon_d4wldsk-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9ODAwIiwicGF0aCI6IlwvZlwvN2ZjZTQ1YmMtMzk1ZC00YjBhLTg0OWQtZGM0ZDFkODdjNzYxXC9kNHdsZHNrLTA3MWE4NTIwLTUxYTgtNGUyYS1hOGEwLWI1NjBhY2MwNzRlMS5wbmciLCJ3aWR0aCI6Ijw9ODAwIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.uJiocUQ4prp_UGGdRQYns2FlpXAx4awa2Rn0tOz31TU" alt="Marching one man band"  />
                </div>


            </Section>

            <Section fullHeight={false} fullWidth bgColor="bg-white flex-col px-2">
                <div className="grid grid-cols-1 md:grid-cols-2 max-w-5xl mx-auto">
                    <img className="w-2/3 md:w-4/5 mx-auto mb-4" src="https://media.istockphoto.com/vectors/sketch-of-a-group-of-students-friends-walking-down-the-street-vector-id1161015867" alt="Marching one man band" srcset="" />
                    <div className="flex flex-col justify-center ">
                        <p className=" text-xl leading-loose my-4 self-center">
                            As projects grew in scale we bought in more people we knew and worked with regularly to provide more finesse and even better service for our clients. Over time this has grown to a collective - a group of freelancers that work together consistently and when the needs arise. With the ability to expand and grow the team depending on the scale of the project we have small overheads and thus can pass on those savings to our clients.
                        </p>
                    </div>

                </div>
            </Section>
            <Section  bgColor="bg-white py-6 lg:py-24 flex-col justify-center px-2 ">
                <div className="max-w-3xl mx-auto text-center">
                <h2 className="text-center">Our team</h2>
                <p className="text-left md:text-center max-w-4xl mx-auto text-xl leading-loose my-4">
                    Antler Digital works with a talented team of digital specialists to create strategic and bespoke  solutions for clients looking to advance their business and digital presence.
                </p>
                <p className="text-left md:text-center mx-auto text-xl leading-loose my-4">
                    Every Antler partner is a connoisseur of their field, from expert cinematic videographers to web development wizards, wordsmith aficionados to artist-level designers, we have the tools and capabilities to provide your business with innovative digital products.
                </p>
                <Button linkTo="/contact" className="mx-auto py-3  mt-8 block px-6 text-xl ">Let's get the ball rolling</Button>
                </div>
            </Section>
        </Layout>
</>
    )
}

AboutUs.propTypes = {

}

export default AboutUs
